import { fjuelApi } from '@fjuel/configs/api';
import { disableIfVariablesUndefined } from '@fjuel/hooks/queries/middlewares/disableIfVariablesUndefined';
import { EnergyMonitoringSchema, EnergyMonitoring } from '@fjuel/schemas';
import { createQuery } from 'react-query-kit';
import { z } from 'zod';

export const useEnergyMonitoring = createQuery<EnergyMonitoring[], { siteId: string | undefined }>({
  primaryKey: 'energy-monitoring',
  queryFn: async ({ queryKey: [, { siteId }] }) =>
    z.array(EnergyMonitoringSchema).parse(await fjuelApi.get(`EnergyMonitoring/${siteId}/current`).json()),
  use: [disableIfVariablesUndefined('siteId')],
});
