import React, { createContext, useContext } from 'react';

interface MenuContextType {
  openMenuItems: {
    shorePowerConnect: boolean;
    pricingAndPaymentSolution: boolean;
    businessPerformance: boolean;
    portCallPlaner: boolean;
    energyMonitoring: boolean;
  };
  setOpenMenuItems: React.Dispatch<
    React.SetStateAction<{
      shorePowerConnect: boolean;
      pricingAndPaymentSolution: boolean;
      businessPerformance: boolean;
      portCallPlaner: boolean;
      energyMonitoring: boolean;
    }>
  >;
}

export const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error('useMenuContext must be used within a MenuProvider');
  }
  return context;
};
