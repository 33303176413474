import { FC } from 'react';
import { useApplicationContext } from '@fjuel/ApplicationContext.tsx';
import { CollapsibleMenu, MenuItem } from '@fjuel/components/SideMenu/CollapsibleMenu.tsx';
import { Plug } from '@phosphor-icons/react';
import { useIsPreview } from '@fjuel/hooks/useIsPreview.ts';

interface EnergyMonitoringProps {
  onMenuClose?: () => void;
}

export const EnergyMonitoring: FC<EnergyMonitoringProps> = ({ onMenuClose }) => {
  const { siteId } = useApplicationContext();
  const isPreview = useIsPreview();

  const items: Array<MenuItem> = [
    {
      label: 'Overview',
      to: `/${siteId}/energy-monitoring`,
      icon: <Plug weight="duotone" />,
      disabled: !siteId,
    },
  ];

  return (
    <>
      {isPreview && (
        <CollapsibleMenu
          id={'energyMonitoring'}
          title={'Energy Monitoring'}
          icon={<Plug />}
          items={items}
          onMenuClose={onMenuClose}
        />
      )}
    </>
  );
};
