import { Box, List } from '@mui/material';
import { FC } from 'react';
import { MenuProvider } from './MenuProvider.tsx';
import { ShorePowerConnect } from './ShorePowerConnect.tsx';
import { PricingAndPayment } from './PricingAndPayment.tsx';
import { PortCallPlanner } from './PortCallPlanner.tsx';
import { BottomBox } from './BottomBox.tsx';
import { Header } from './Header.tsx';
import { BusinessPerformance } from './BusinessPerformance.tsx';
import { EnergyMonitoring } from '@fjuel/components/SideMenu/EnergyMonitoring.tsx';

interface SideMenuProps {
  onClose?: () => void;
}

export const SideMenu: FC<SideMenuProps> = ({ onClose }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
        p: 2,
        width: '100%',
        height: '100vh',
      }}
      component={'aside'}
    >
      <Header onClose={onClose} />
      <MenuProvider>
        <Box sx={{ overflowY: 'auto', width: '100%', flexGrow: 1 }}>
          <List sx={{ maxWidth: '100%' }}>
            <ShorePowerConnect onMenuClose={onClose} />
            <PricingAndPayment onMenuClose={onClose} />
            <BusinessPerformance onMenuClose={onClose} />
            <PortCallPlanner onMenuClose={onClose} />
            <EnergyMonitoring onMenuClose={onClose} />
          </List>
        </Box>
      </MenuProvider>
      <BottomBox />
    </Box>
  );
};
