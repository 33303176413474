import { z } from 'zod';

export const PowerMetricsSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  voltage: z.number(),
  current: z.number(),
  activePower: z.number(),
  reactivePower: z.number(),
  powerFactor: z.number(),
});

export type PowerMetrics = z.infer<typeof PowerMetricsSchema>;
