import { FC, ReactNode, useState } from 'react';
import { MenuContext } from './MenuContext';

export const MenuProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [openMenuItems, setOpenMenuItems] = useState({
    pricingAndPaymentSolution: false,
    shorePowerConnect: true,
    businessPerformance: false,
    portCallPlaner: false,
    energyMonitoring: false,
  });

  return <MenuContext.Provider value={{ openMenuItems, setOpenMenuItems }}>{children}</MenuContext.Provider>;
};
