import { createContext, useContext } from 'react';

export interface DeviceContextType {
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const DeviceContext = createContext<DeviceContextType>({ isDesktop: false, isTablet: false, isPhone: false });

export const useDevice = (): DeviceContextType => useContext(DeviceContext);
